.dialog-overlay {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(15,43,75,0.8);
	z-index: 999999;
}

.dialog-aligner {
	width: 100%;
	height: 100vh;
	position: relative;
}

.dialog-overlay-whitebox {
	background-color: #FFFFFF;
	box-shadow: 0 0px 5px 0;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	word-wrap: break-word;
}

.dialog-overlay-header {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 15px;
}

.dialog-overlay-text {
	font-size: 16px;
}

.dialog-overlay-text a {
	display: block;
	padding: 15px 0px 15px 0px;
}

.loader {
	margin: 0 auto;
	margin-top: 0px;
	position: relative;
	border: 4px solid rgba(0,0,0,0); 
	border-top: 4px solid #3984BE;
	border-left: 4px solid #3984BE;
	border-right: 4px solid #3984BE;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    animation: spin 1s linear infinite;
}


.dialog-overlay-waitmessage {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	/* padding: 30px;  */
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	
	text-align: center;
	font-size: 18px;
	color: #FFFFFF;

}

.dialog-overlay-waitmessage .loader {
	margin: 0 auto;
	margin-top: 0px;
	margin-bottom: 32px;
	position: relative;
	border: 4px solid rgba(0,0,0,0); 
	border-top: 4px solid #FFFFFF;
	border-left: 4px solid #FFFFFF;
	border-right: 4px solid #FFFFFF;
    border-radius: 50%;
    width: 88px;
    height: 88px;
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}